import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import handleRequest from "../util/handle_request"

export default class extends Controller {
  static targets = ["discount", "reason"]
  static values = {
    rejectDiscountPath: String
  }

  showReason() {
    this.reasonTarget.classList.remove("hidden")
    this.discountTarget.classList.add("hidden")
  }

  async rejectDiscount() {
    handleRequest(post, this.rejectDiscountPathValue)
    this.showReason()
  }
}
